import React from 'react'

const Play = () =>
    <svg xmlns="http://www.w3.org/2000/svg" width="63" height="49" viewBox="0 0 127 98">
        <g>
            <g>
                <path fill="#05d0e5" d="M126.457 63.996c.377 4.479.479 9.059.412 13.5-.101 3.512-.931 6.82-2.306 9.677-4.767 9.773-7.43 13.57-17.315 8.576-.044-.023-25.493-21.832-29.28-24.45l-.208-.14c-7.85-5.163-20.457-5.096-28.17.14-3.822 2.618-30.623 24.52-30.623 24.52C5.125 102.773 1.03 92.27.687 77.496c-.104-4.441.069-9.021.415-13.5.345-4.545.895-9.23 1.685-13.845.864-4.75 1.894-9.468 3.205-13.978a105.175 105.175 0 0 1 4.44-12.538c3.273-7.61 8.714-14.12 15.947-18.252 9.814-5.716 18.768-5.03 28.17-4.236 2.997.24 6.06.516 9.228.516 3.135 0 6.2-.276 9.23-.516 9.436-.793 18.323-1.48 28.172 4.236 7.198 4.133 12.674 10.643 15.944 18.252 1.688 3.925 3.17 8.197 4.442 12.538a139.557 139.557 0 0 1 3.167 13.978c.829 4.616 1.379 9.3 1.725 13.845zM90.76 22.148c-2.876 0-5.237 2.283-5.237 5.16 0 2.877 2.36 5.236 5.237 5.236 2.839 0 5.16-2.36 5.16-5.236a5.15 5.15 0 0 0-5.16-5.16zM52.273 39.547c1.696 0 3.134-1.401 3.134-3.133 0-1.733-1.438-3.134-3.134-3.134h-11.17V22.148c0-1.77-1.4-3.171-3.132-3.171-1.696 0-3.096 1.4-3.096 3.17V33.28h-11.17a3.135 3.135 0 0 0-3.138 3.134 3.135 3.135 0 0 0 3.138 3.133h11.17v11.132c0 1.733 1.4 3.133 3.096 3.133a3.13 3.13 0 0 0 3.132-3.133V39.547zm19.317 14.23c1.733 0 3.134-1.403 3.134-3.098 0-1.695-1.401-3.095-3.134-3.095H56.07c-1.731 0-3.132 1.4-3.132 3.095 0 1.695 1.4 3.099 3.133 3.099zm0-28.534c1.733 0 3.134-1.401 3.134-3.095a3.131 3.131 0 0 0-3.134-3.134H56.07a3.131 3.131 0 0 0-3.132 3.134c0 1.694 1.4 3.095 3.133 3.095zm10.064 16.369c2.839 0 5.198-2.36 5.198-5.198 0-2.84-2.36-5.198-5.198-5.198-2.84 0-5.198 2.359-5.198 5.198 0 2.839 2.359 5.198 5.198 5.198zm9.106 9.067c2.839 0 5.16-2.322 5.16-5.161 0-2.876-2.321-5.235-5.16-5.235-2.876 0-5.237 2.359-5.237 5.235 0 2.839 2.36 5.16 5.237 5.16zm9.07-9.067c2.873 0 5.16-2.36 5.16-5.198 0-2.84-2.287-5.198-5.16-5.198-2.876 0-5.235 2.359-5.235 5.198 0 2.839 2.359 5.198 5.236 5.198z"/>
            </g>
        </g>
    </svg>

export default Play;