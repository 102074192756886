import React from 'react'

const Heart = () =>
    <svg xmlns="http://www.w3.org/2000/svg" width="53" height="48" viewBox="0 0 106 95">
        <g>
            <g>
                <path fill="#05d0e5" d="M103.788 23.025c-1.661-4.86-4.47-9.274-8.082-12.841-3.67-3.583-8.197-6.347-13.128-7.912-.95-.306-2.1-.508-3.358-.63-9.364-.907-18.555 3.14-24.458 10.464-1.715 2.127-3.263 4.222-4.144 5.807-2.696 4.848-4.631 10.262-5.164 16.055-.928-5.738-.423-11.821 1.113-17.658.866-3.31 2.056-6.544 3.496-9.684-3.212-2.217-6.726-3.873-10.417-4.841-5.274-1.39-10.95-1.363-16.682.472a32.748 32.748 0 0 0-13.07 7.913 32.13 32.13 0 0 0-8.111 12.855c-1.864 5.573-1.92 11.09-.543 16.235 1.432 5.36 4.44 10.32 8.655 14.517l40.01 40.04v-.015c1.577 1.562 4.128 1.577 5.704.015l39.867-39.853.23-.187c4.185-4.197 7.164-9.128 8.599-14.517 1.377-5.16 1.318-10.662-.517-16.235z"/>
            </g>
        </g>
    </svg>

export default Heart;