import React, { PureComponent } from 'react'
import { Row, Col, Container } from 'reactstrap';
import CoreCoin from '../../images/corecoin.png'
import CoreToken from '../../images/coretoken.png'
import Man from '../../images/network_man.png'

import './Network.scss'

export default class Network extends PureComponent{
    static propTypes = {
    }

    render() {
        return (
            <div className='network'>
                <div className='banner'>
                    <Container>
                        <Row>
                            <Col xs={12} md={{ size: 4, offset: 1 }}  xl={{ size: 5, offset: 1 }}>
                                <img src={Man} className='img-fluid' alt='man'/>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div id='network'>
                    <div id='why-wallace' />
                    <Container>
                        <Row>
                            <Col xs='12'>
                                <h2>Why Wallace</h2>
                                <p>
                                    <span>01</span>
                                    Adoption of the revolutionary Core Coin network
                                </p>
                                <p>
                                    <span>02</span>
                                    A fully customizable, self-sustaining, digital avatar offering high entertainment-value
                                </p>
                                <p>
                                    <span>03</span>
                                    Built on an AI and ML platform allowing Wallace to learn from users
                                </p>
                                <p>
                                    <span>04</span>
                                    A digital wallet for managing multiple FIAT, cryptocurrencies, assets, commodities, and tokens
                                </p>
                                <p>
                                    <span>05</span>
                                    Opportunity to send and receive any Fiat or cryptocurrency 24/7 globally,  avoiding the high fees charged for bank transactions                            </p>
                                <p>
                                    <span>06</span>
                                    Automated savings account accessible to users at the age of 18 to provide funding towards student loans or starting a career
                                </p>
                                <p>
                                    <span>07</span>
                                    Wallace personalization store lets users to exchange items such as skins, toys, and treats for tokens                            </p>
                                <p>
                                    <span>08</span>
                                    Access to multiple Wallace’s achievement awards
                                </p>
                                <p>
                                    <span>09</span>
                                    Completely integrated decentralized e-commerce platform that allows users to buy or sell products & services worldwide
                                </p>
                                <p>
                                    <span>10</span>
                                    Unique crowdfunding platform where users can request products or services through sponsorship or donation
                                </p>
                                <p>
                                    <span>11</span>
                                    Smart contract platform allowing users to trade value
                                </p>
                                <p>
                                    <span>12</span>
                                    Loans and other financial services
                                </p>
                            </Col>
                        </Row>
                    </Container>
                    <div className='divider'>
                        <Container>
                            <Row>
                                <Col xs={12} md={{ size: 10, offset: 1 }} lg={{ size: 8, offset: 2 }}>
                                    <div className='divider_quote'>
                                        <h4>
                                            The greatest good you can do for another is not just sharing your riches, but to reveal to him his own.
                                        </h4>
                                        <p>
                                            - Benjamin Disraeli
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Container>
                        <Row>
                            <Col xs={12} lg={{ size: 10, offset: 1 }} xl={{ size: 8, offset: 2 }}>
                                <Row className='logos'>
                                    <Col xs={12} md={6}>
                                        <a href='https://coretoken.net/' target='_blank' rel="noopener noreferrer"><img src={CoreToken} alt='Core Token'/></a>
                                        <p>Wallace is powered by a Smart Contract platform </p>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <a href='https://coreblockchain.net/' target='_blank' rel="noopener noreferrer"><img src={CoreCoin} alt='Core Blockchain' /></a>
                                        <p>Running on the powerful Core Blockchain network </p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        )
    }
}
