import React, { PureComponent } from 'react'
import { Row, Col, Container } from 'reactstrap';

import Twitter from './svg/tw';
import Youtube from './svg/yt';
import Github from './svg/gh';
import Facebook from './svg/fb';
import Linkedin from './svg/linkedin'

import './Footer.scss'

export default class Footer extends PureComponent{

    render() {
        return (
            <div className="contacts">
                <div id="contact" />
                <Container className="footer-container">
                    <Row>
                        <Col xs='12' md='12' lg='6'>
                            <p className="contacts-info">
                                Ask a question • <a href="mailto:contact@wallace.world">contact@wallace.world</a>
                            </p>
                            <p className="contacts-info">
                                Press and public relations • <a href="mailto:public@wallace.world">public@wallace.world</a>
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs='12' md='12' lg='6' className="footer-md-center" >
                            <ul className="footer-social-network">
                                <li>
                                    <a href="https://twitter.com/WallaceWorldApp" target="_blank" rel="noopener noreferrer">
                                        <Twitter />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.facebook.com/WallaceWorldApp" target="_blank" rel="noopener noreferrer">
                                        <Facebook />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/channel/UCpzp0Tfk1_6LgH3PpsmzJDw" target="_blank" rel="noopener noreferrer">
                                        <Youtube />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/company/15862055/" target="_blank" rel="noopener noreferrer">
                                        <Linkedin />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://github.com/wallace-world" target="_blank" rel="noopener noreferrer">
                                        <Github />
                                    </a>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs='12' md='12' lg='6' className="footer">
                            Copyright © 2019 - {(new Date().getFullYear())}	Wallace.world	All right reserved
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
