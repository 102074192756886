import React, { PureComponent } from 'react'
import { Row, Col, Container} from 'reactstrap';

import Paw from './svg/paw'
import Pig from './svg/pig'
import Play from './svg/play'
import Heart from './svg/heart'
import Store from './svg/store'
import Cupboard from './svg/cupboard'

import Woman from '../../images/woman.png'
import Man from '../../images/man.png'
import Goals from '../../images/goals.png'
import GoalsXS from '../../images/goals_xs.png'

import './AboutUs.scss'

export default class AboutUs extends PureComponent{

    render() {
        return (
            <div className='aboutUs'>
                <div id='about'></div>
                <Container>
                    <Row>
                        <Col xs='12'>
                            <h2>About</h2>
                            <p><b>A liquid platform, for a new generation, built on Blockchain Technology</b></p>
                            <ul className='columns'>
                                <li><p>Ingenious</p></li>
                                <li><p>Socially impactful</p></li>
                                <li><p>Custom-fit</p></li>
                                <li><p>Self-sustaining</p></li>
                                <li><p>Secure</p></li>
                                <li><p>Easily traceable</p></li>
                                <li><p>Accountable</p></li>
                                <li><p>Blockchain technology</p></li>
                            </ul>
                            <p>
                                Wallace is a self-sustainable decentralized ecosystem with a 98% global reach.
                                Providing access to a stable income, health, education, social, financial services and job opportunities, in a secure and liquid environment regardless of your geographical position.
                            </p>
                            <h2>Wallace mission</h2>
                            <p>
                                Wallace’s mission is to improve the lives of both current and future generations through its seamless, secure, transparent, and trustworthy environment, aimed at facilitating communications and data transactions.
                                The decentralized and self-sustainable platform is aligned with United Nations' 17 Sustainable Developments Goals.
                                Illustrating the platform’s dedication to future needs of a global society.
                            </p>
                            <h2>WALLACE MOTTO</h2>
                            <ul>
                                <li>
                                    <p>
                                        Everyone deserves an equal opportunity at life!
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Financial stability, education, job opportunities, financial services, social interaction and good health services are not a privilege, but a right!
                                    </p>
                                </li>
                            </ul>
                            <p>
                                A decentralized blockchain (with the support of AI and ML), will provide a foundation towards 21st century communications, data management, identification, social interaction and transactional solutions!                            </p>
                        </Col>
                        <Col xs={12} md={7} className='d-none d-lg-block'>
                            <a href='https://sustainabledevelopment.un.org/' target='_blank' rel="noopener noreferrer"><img src={Goals} className='img-fluid goals' alt='goals'/></a>
                        </Col>
                        <Col xs={12} md={7} className='d-block d-lg-none'>
                            <a href='https://sustainabledevelopment.un.org/' target='_blank' rel="noopener noreferrer"><img src={GoalsXS} className='img-fluid goals' alt='goals'/></a>
                        </Col>
                    </Row>
                </Container>
                <div className='whiteBlock'>
                    <div id='features' />
                    <div className="banner" />
                    <Container>
                        <Row>
                            <Col className='featuresTitle' xs='12' md='12' lg='12'>
                              <h2>Features</h2>
                            </Col>
                            <Col xs='12' md='6' lg='4'>
                                <div className='whiteBlock_card'>
                                    <div className='icon'>
                                        <Paw/>
                                    </div>
                                    <h3>Avatar</h3>
                                    <p>
                                        Wallace is a digital avatar which can be customized according to the user’s personal preference.
                                        Each user will have a different level of understanding on owning a wallet.
                                        Which is why we created a “friend” that will guide users through the financial world, educating them on the responsibilities around managing their assets.
                                    </p>
                                    <hr/>
                                </div>
                            </Col>
                            <Col xs='12' md={{ size: 6, order: 2 }} lg={{ size: 4, order: 0 }}>
                                <div className='whiteBlock_card'>
                                    <div className='icon'>
                                        <Pig/>
                                    </div>
                                    <h3>Piggy Bank</h3>
                                    <p>
                                        Having a full financial backend support, Wallace’s piggy bank works very similar to any bank account.
                                        Wallace is a Blockchain based decentralized wallet that can hold multiple FIAT and  crypto currencies, assets, commodities and tokens.
                                        Wallace even has the means to provide official loans to users of age.
                                        The wallet also acts as a savings account and offers users to send and receive currencies.
                                    </p>
                                    <hr/>
                                </div>
                            </Col>
                            <Col xs={{ size: 12, order: 'first' }} md={{ size: 6, order: 0 }} lg='4' className='woman'>
                                <div className='whiteBlock_card'>
                                    <div className='img'>
                                        <img src={Woman} alt='woman'/>
                                    </div>
                                    <div className='icon'>
                                        <Play/>
                                    </div>
                                    <h3>Activities</h3>
                                    <p>
                                        Wallace is built using an artificial intelligence and machine learning platform which allows the decentralized application to learn from it's users.
                                        Wallace will grow around the individual user, based on the usage.
                                        Wallace will prompt the user to participate in various activities, focused to entertain the users, subliminally educating, socializing and taking care of the user’s health.
                                    </p>
                                    <hr/>
                                </div>
                            </Col>
                            <Col xs={{ size: 12, order: 'last' }} md={{ size: 6, order: 1 }} lg={{ size: 4, order: 0 }} className='man'>
                                <div className='whiteBlock_card'>
                                    <div className='img'>
                                        <img src={Man} alt='man'/>
                                    </div>
                                    <div className='icon'>
                                        <Store/>
                                    </div>
                                    <h3>Share</h3>
                                    <p>
                                        Wallace has the ability to share funds/products/services, with the help of a smart contract platform that is enabled automatically,
                                        and will offer a repayment method. Wallace will verify funds,
                                        to ensure that the lender can pay back without disrupting their daily income and nutritional values.
                                        The users also has the ability to sponsor or donate to a less fortunate person anywhere in the world.
                                        Age restriction will be applicable.
                                    </p>
                                    <hr/>
                                </div>
                            </Col>
                            <Col xs='12' md='6' lg='4'>
                                <div className='whiteBlock_card'>
                                    <div className='icon'>
                                        <Cupboard/>
                                    </div>
                                    <h3>Cupboard</h3>
                                    <p>
                                        Wallace has the ability to entertain itself through its interactive system and thanks to our Artificial Intelligence and Machine Learning Technology.
                                        Rewards are issued for Wallace’s achievements through treats, toys, new looks, and other incentivization models.
                                        All neatly stored in the Wallace cupboard which can also be used when interacting with Wallace and other friends.
                                    </p>
                                    <hr/>
                                </div>
                            </Col>
                            <Col xs='12' md='6' lg='4'>
                                <div className='whiteBlock_card'>
                                    <div className='icon'>
                                        <Heart/>
                                    </div>
                                    <h3>Shop & Market</h3>
                                    <p>
                                        Users are able to purchase in-app items such as skins, toys, treats, etc. using tokens or any of their other stored values on the Wallace personalization store.
                                        Wallace has a fully  integrated decentralized e-commerce platform that allows users to buy or sell products or services anywhere in the world.
                                        The e-commerce platform also has a unique crowdfunding platform where users can request certain products or services for sponsorship or donation.
                                    </p>
                                    <hr/>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        )
    }
}
