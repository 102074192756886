import React from 'react'

const Pig = () =>
    <svg xmlns="http://www.w3.org/2000/svg" width="63" height="49" viewBox="0 0 126 97">
        <g>
            <g>
                <path fill="#05d0e5" d="M39.376 96.135H30.37a2.95 2.95 0 0 1-2.95-2.95l-2.483-16.953a2.95 2.95 0 0 1 2.949-2.95h13.972a2.95 2.95 0 0 1 2.95 2.95l-2.484 16.953a2.95 2.95 0 0 1-2.95 2.95z"/>
            </g>
            <g>
                <path fill="#05d0e5" d="M100.107 96.135h-9.005a2.95 2.95 0 0 1-2.949-2.95L85.67 76.232a2.95 2.95 0 0 1 2.95-2.95h13.971a2.95 2.95 0 0 1 2.95 2.95l-2.484 16.953a2.95 2.95 0 0 1-2.949 2.95z"/>
            </g>
            <g>
                <path fill="#05d0e5" d="M24.937 71.038l-8.283-.034c-7.169.333-10.337-1.639-11.716-6.704L.4 47.626c-.925-3.399 1.098-6.935 4.495-7.859l10.287-2.8c5.065-1.378 10.337 1.637 11.716 6.703l4.211 14.638c1.378 5.064-1.106 11.353-6.17 12.731z"/>
            </g>
            <g>
                <path fill="#05d0e5" d="M40.64 6.09c-.158-.102-15.905-10.069-31.362-2.153a.5.5 0 0 0-.27.448c.002.187.426 18.812 19.334 25.896a.491.491 0 0 0 .617-.24L40.812 6.733a.497.497 0 0 0-.173-.643z"/>
            </g>
            <g>
                <path fill="#05d0e5" d="M124.303 25.478a2.49 2.49 0 0 0-2.913 1.104c-3.147 5.204-8.755 6.21-11.15 6.64-.885.159-1.29.233-1.68.56a1.488 1.488 0 0 0-.452 1.626l3.938 11.487a1.49 1.49 0 0 0 1.97.897c9.712-3.932 11.727-14.06 12.064-19.746a2.498 2.498 0 0 0-1.777-2.568z"/>
            </g>
            <g>
                <path fill="#05d0e5" d="M64.13.502c35.431 0 53.777 21.953 53.777 47.194 0 25.242-24.077 45.705-53.777 45.705S10.354 72.938 10.354 47.696C10.354 22.456 28.735.502 64.13.502zm-34.457 39.7a3.245 3.245 0 1 0 0-6.491 3.245 3.245 0 0 0 0 6.49zM81.116 9.313C63.67 3.412 48.218 9.095 47.57 9.34l1.577 4.184c.143-.055 14.59-5.37 30.538.025z"/>
            </g>
            <g>
                <path fill="#05d0e5" d="M25.606 79.58c9.77 8.523 23.417 13.82 38.524 13.82 29.153 0 52.884-19.716 53.75-44.313C88.4 88.49 44.808 83.663 25.606 79.58z"/>
            </g>
        </g>
    </svg>

export default Pig;