import React, { PureComponent } from 'react';
import { Row, Col, Container } from 'reactstrap';

import './Feature.scss'

export default class Feature extends PureComponent{

    render() {
        return (
            <div id='features'>
                <Container>
                    <Row className='feature-block'>
                        <Col xs='6' md='6' className='feature-block-img'>
                            <h2 className='color-black text-center'>DARK VERSION</h2>
                            <img src={require('../../images/version-black-img.png')} className='img-left' alt='dark version'/>
                        </Col>
                        <Col xs='6' md='6' className='feature-block-img'>
                            <h2 className='text-center'>LIGHT VERSION</h2>
                            <img src={require('../../images/version-light-img.png')} className='img-right' alt='light version'/>
                        </Col>
                     </Row>
                </Container>
            </div>
        );
    }
}
