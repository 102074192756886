import React, { PureComponent } from 'react'
import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem,
    NavLink,
    NavbarBrand
} from 'reactstrap'


import Logo from '../../images/logo.png'

export class Menu extends PureComponent{
    constructor(props) {
        super(props)

        this.toggleNavbar = this.toggleNavbar.bind(this)
        this.state = {
            collapsed: true
        }
        this.menuHeight = undefined
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        })
    }

    closeNavbar = () => {
        this.setState({
            collapsed: true
        })
    }

    scroll = () => {
        if(this.menuHeight <= window.pageYOffset) {
            this.setState({
                sticky: true
            })
        } else {
            this.setState({
                sticky: false
            })
        }
    }

    resize = () => {
        if(window.screen.width <= 767) {
            document.removeEventListener('scroll', this.scroll);
            this.setState({
                sticky: true
            })
        } else {
            this.menuHeight = document.getElementsByClassName('last')[0].offsetTop
            document.addEventListener('scroll', this.scroll);
            this.scroll();
        }
    }

    componentDidMount () {
        window.addEventListener('resize', this.resize);
        this.resize();
    }

    render() {
        return (
            <Navbar color="faded" light expand='xl' fixed={this.state.sticky ? 'top' : ''}>
                <NavbarBrand href="/">
                    <img src={Logo} className='logo' alt='Wallace' />
                </NavbarBrand>
                <NavbarToggler onClick={this.toggleNavbar} className={!this.state.collapsed ? 'close' : ''} />
                <Collapse isOpen={!this.state.collapsed} navbar>
                    <Nav navbar vertical={!this.state.sticky} className='menu'>
                        <NavItem className='menu_item' onClick={this.closeNavbar}>
                            <NavLink href='#about'>about</NavLink>
                            {this.state.sticky && this.state.collapsed && (
                                <span>&#8226;</span>
                            )}
                        </NavItem>
                        <NavItem className='menu_item' onClick={this.closeNavbar}>
                            <NavLink href='#features'>features</NavLink>
                            {this.state.sticky && this.state.collapsed && (
                                <span>&#8226;</span>
                            )}
                        </NavItem>
                        <NavItem className='menu_item' onClick={this.closeNavbar}>
                            <NavLink href='#how-it-works'>how it works</NavLink>
                            {this.state.sticky && this.state.collapsed && (
                                <span>&#8226;</span>
                            )}
                        </NavItem>
                        <NavItem className='menu_item' onClick={this.closeNavbar}>
                            <NavLink href='#why-wallace'>why wallace</NavLink>
                            {this.state.sticky && this.state.collapsed && (
                                <span>&#8226;</span>
                            )}
                        </NavItem>
                        <NavItem className='menu_item last' onClick={this.closeNavbar}>
                            <NavLink href='#contact'>contact</NavLink>
                        </NavItem>
                    </Nav>
                </Collapse>
            </Navbar>
        )
    }
}
