import React from 'react'

const Paw = () =>
    <svg xmlns="http://www.w3.org/2000/svg" width="58" height="53" viewBox="0 0 116 107">
        <g>
            <g>
                <path fill="#05d0e5" d="M66.035 6.912c-5.176-7.37-14.676-8.584-21.918-3.47v-.035c-4.176 2.915-7.074 7.466-8.37 12.351-1.324 4.91-1.063 10.284 1.067 14.937.456.983 1 1.932 1.604 2.814 2.21 3.113 5.243 5.176 8.6 6.095 3.375.886 7.025.591 10.5-.983.95-.46 1.915-.982 2.815-1.636 4.162-2.949 7.06-7.47 8.372-12.385 1.587-5.898.865-12.676-2.67-17.688zM.124 26.304C.288 34.231 5.692 41.44 13.36 43.7c8.417 2.456 16.246-3.146 16.639-11.89.18-4-1.245-8.257-3.355-11.273l-.345-.425c-2.28-2.915-5.767-5.632-9.58-6.78-8.813-2.619-16.79 3.9-16.595 12.972zm75.213 2.13c-3.57 3.57-6.045 8.352-6.501 13.432-.805 8.811 4.977 16.41 13.937 17.261 1.047.067 2.16.067 3.29 0 5.079-.49 9.846-2.949 13.432-6.552 3.59-3.539 6.062-8.385 6.52-13.43a18.114 18.114 0 0 0 0-3.244v-.03c-.344-3.8-1.934-7.112-4.39-9.567h.017c-2.442-2.426-5.766-4.062-9.597-4.39h-.101a25.727 25.727 0 0 0-3.142 0c-5.096.458-9.88 2.948-13.465 6.52zm20.572 34.658c-4.045.852-7.895 3.012-10.5 6.256-.59.785-1.096 1.54-1.488 2.294-1.476 2.751-1.886 5.764-1.295 8.581a12.508 12.508 0 0 0 4.619 7.34c.654.556 1.426 1.046 2.26 1.506 3.684 1.965 8.075 2.424 12.12 1.573 4.079-.853 7.91-3.016 10.516-6.29.574-.72 1.064-1.506 1.49-2.227l.097-.26c3.294-6.489.658-13.829-5.7-17.234-3.813-1.834-7.942-2.425-12.12-1.539zM25.66 47.138C16.373 50.02 9.526 57.456 8.299 67.382l-.034.393c-1.278 11.726 6.802 19.173 22.307 20.753 0 0 3.705.613 5.045 1.311 1.418.74 4.548 5.231 4.548 5.231 5.925 10.558 10.195 12.934 18.939 10.18h.035c2.784-.916 5.454-2.26 7.794-4.062a24.084 24.084 0 0 0 5.915-6.354c1.687-2.623 2.865-5.698 3.375-9.106.442-3.046.325-6.358-.494-9.893-.965-4.129-2.096-7.829-3.375-11.106-2.683-6.91-6.714-13.757-13.313-17.557-6.568-3.767-14.562-3.868-21.834-2.754-3.455.527-7.222 1.408-11.317 2.654z"/>
            </g>
        </g>
    </svg>

export default Paw;