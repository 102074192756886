import React, { PureComponent } from 'react'
import { Row, Col, Container } from 'reactstrap';

import Image from '../../images/how-it-works-img.png'

import './HowItWorks.scss'

export default class HowItWorks extends PureComponent{

    render() {
        return (
            <div className='howItWorks'>
                <div id='how-it-works'/>
                <Container>
                    <Row>
                        <Col xs='12' md='7' lg='6' xl='6'>
                            <h2>How it works</h2>
                            <p>We keep things simplistic!</p>
                            <p>
                                Users create a Wallace - a fully customizable digital avatar.
                                Wallace allows its' users to invest in themselves; specifically in, educational and social activities, whilst promoting physical activities and human connection.
                                Through its interactive and all incentivized entertainment platform, offering intrinsic value to its ecosystem.
                                Wallace represents a closed-loop macro economy, integrated with Core Groups suite of services.
                                Comprised of, Hybrid Exchange, Banking as a Service, lending as a service, E-commerce platform, payment gateway, and a complete medical supply chain management platform.
                                The implementation of Core Coin, allows for secure and trusted communication and data transactions. One of the most adept & cost-effective blockchains, transmitting without internet.
                            </p>
                        </Col>
                        <Col xs='12' md='5' lg={{ size: 5, offset: 1}} xl={{ size: 4, offset: 1}}>
                            <img src={Image} alt='How it works' />
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
