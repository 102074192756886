import React, { PureComponent } from 'react'
import {
    Row,
    Col,
    Container,
} from 'reactstrap';

import { Menu } from './Menu'

import Banner from '../../images/mobile.png'

import './Header.scss'

export default class Header extends PureComponent{


    render() {
        return (
            <div id='header'>
                <Container>
                    <Row>
                        <Col xs='12' md='5' lg='4' xl='4'>
                            <Menu/>
                        </Col>
                        <Col xs='12' xl='8' className='header_content'>
                            <h3>A  global self sustainable solution uniting humanity </h3>
                            <div className='buttons'>
                                <a href="https://www.youtube.com/watch?v=IpwbHthLsZ8" className="btn btn-secondary" target="_blank" rel="noopener noreferrer">watch teaser</a>
                                <a href="https://drive.google.com/a/wallit.eu/file/d/1wyam75DfefDb2EDNH-rQojwQMvE6ETCV/view?usp=sharing" className="btn btn-secondary" target="_blank" rel="noopener noreferrer">white paper</a>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs='12' md={{ size: 11, offset: 1 }} >
                           <div className='banner'>
                               <img src={Banner} className='img-fluid' alt='Wallace' />
                           </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
