import React, { PureComponent } from 'react'


import Header from '../components/Header'
import Network from '../components/Network'
import Feauture from '../components/Feature'
import AboutUs from '../components/AboutUs'
import Footer from '../components/Footer'
import HowItWorks from '../components/HowItWorks'

class PageContainer extends PureComponent {

    render() {

        return(
            <>
                <Header/>
                <AboutUs/>
                <HowItWorks/>
                <Network/>
                <Feauture/>
                <Footer/>
            </>
        )
    }
}


export default (PageContainer)
