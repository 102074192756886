import React, { PureComponent } from 'react'

import PageContainer from './containers/PageContainer'

export default class App extends PureComponent {
    render(){
        return(
            <PageContainer />
        )
    }
}